import cookie from "react-cookies";

export const appUse = cookie.load("appUse");
export const token = cookie.load("token");
export const user = cookie.load("user");

const isDev = false;

export const proxyurl = isDev
    ? "https://cors.dc3cloud.com.br/"
    : "https://cors.dc3cloud.com.br/";
export const urlAPI = proxyurl + cookie.load("urlAPI") + "/api/request";
export const url = isDev
    ? "http://192.168.100.5:8080"
    : "https://auth.dc3cloud.com.br";

export const urlAPIAccess = isDev
    ? "http://localhost:8080"
    : proxyurl + "https://auth.dc3cloud.com.br";
export const urlAPIAccessImg = isDev
    ? "http://192.168.100.5:8080"
    : "https://auth.dc3cloud.com.br";
